import React, { Component } from "react"
import { Link, graphql } from "gatsby"
import SEO from "../components/seo"
import Parser from 'html-react-parser';
import SearchBlog from '../components/BlogSearch'

class RecettesPage extends Component {


state = {
  ShowOrHide: "",
 }

toggleHide = () => {
  this.setState({ ShowOrHide: "hide" })
};

toggleShow = () => {
  this.setState({ ShowOrHide: "" })
};

  render() {
    const { ShowOrHide } = this.state
    var index = 0;
    const data = this.props.data;
    
    let previousButton;
    if (this.props.pageContext.pageNumber === 0) {
      previousButton = "";
    } else {
      previousButton = <Link className="previous" to={this.props.pageContext.previousPagePath}>Précédentes...</Link>;
    }

    let nextButton;
    if (this.props.pageContext.pageNumber > 1 && this.props.pageContext.pageNumber === (this.props.pageContext.numberOfPages - 1)) {
      nextButton = "";
    } else {
      nextButton = <Link className="next" to={this.props.pageContext.nextPagePath}>Suivantes...</Link>;
    }
    return (
      <>
      <SEO title="Anarchy In The Kitchen - Mes Recettes" description="Mes recettes pour bousculer votre cuisine."/>
          <div className="maincontent">

          <section className="header-recette">
            <h2 data-aos="fade">Mes recettes de la mort qui font décéder !</h2>
          </section>
          <SearchBlog onHide={this.toggleHide} onShow={this.toggleShow}></SearchBlog>
          <div className={ShowOrHide}>
          {data.allWordpressPost.edges.map(({ node }) => (
           <div key={index} className={"blog-card " + (index++ % 2 === 0 ? 'alt' : '')} data-aos="fade">
            <div className="meta">
              
              <Link to={"../../recettes/" + node.slug}>
                <object className="photo" data={node.jetpack_featured_media_url} type="image/jpg">
                </object>
              </Link>
            </div>
            <div className="description">
            <Link to={"../../recettes/" + node.slug}>
              <h1 dangerouslySetInnerHTML={{ __html: node.title }}></h1>
            </Link>
              
              <div className="cat">
              {node.categories.length > 1 && node.categories.map(( cat ) => (
                <span key={cat} >
                  {cat === 820457 ? " Lactose Free " : ""}
                  {cat === 28249 ? " Gluten Free " : ""}
                  {cat === 186239 ? " Flexible " : ""}
                </span>
              ))}
              </div> 
              <div>
                {Parser(node.excerpt, {
                      replace: function(domNode) {
                        if (domNode.name === 'a') {
                          return (
                            <span >
                              ...
                              <br></br>
                              <span className="read-more">
                                <Link to={"../../recettes/" + node.slug}><b>Lire la suite...</b> </Link>
                              </span>
                            </span>)
                        }
                      }})}
              </div>
              <div className="date">{node.date}</div>
            </div>
            
          </div>
           ))}
           <section className="footer-recette">

              {previousButton}
              {nextButton}
              
            </section>
            </div>
          </div>

          
      </>
    )
  }
}


export default RecettesPage




export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    allWordpressPost(
      filter: {categories: {eq: 244704}},
      sort: { fields: date, order: DESC },
      skip: $skip,
      limit: $limit
    ){
    edges {
      node {
        title
        excerpt
        slug
        categories
        date(formatString: "DD/MM/YYYY")
        jetpack_featured_media_url
      }
    }
  }
    
  }
`
