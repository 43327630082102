import React, { useState } from "react"
import Parser from 'html-react-parser';
import { Link, useStaticQuery,graphql } from "gatsby"

export const SearchBlog = ( { onHide, onShow } ) => {
    
  const  data  = useStaticQuery(graphql`
  query {
    allWordpressPost(
      filter: {categories: {eq: 244704}},
      sort: { fields: date, order: DESC }
        ){
        edges {
            node {
                title
                excerpt
                slug
                categories
                date(formatString: "DD/MM/YYYY")
                jetpack_featured_media_url
            }
        }
    }
  }
`)

  const allPosts = data.allWordpressPost.edges
  const emptyQuery = ""

  const [state, setState] = useState({
    filteredData: [],
    query: emptyQuery,
    isGlutenFreeActif: false,
    isLactoseFreeActif: false,
    isFlexibleActif: false,
  })
  
  const handleInputChange = event => {
    const {isGlutenFreeActif, isLactoseFreeActif, isFlexibleActif} = state;
    search(event.target.value, isGlutenFreeActif, isLactoseFreeActif, isFlexibleActif);
  }


  function search(queryString, isGlutenFreeActif, isLactoseFreeActif, isFlexibleActif){
      const query = queryString;
      const posts = allPosts || [];
      const catList = [ 244704 ];
      if(isGlutenFreeActif)
        catList.push(28249);
      if(isLactoseFreeActif)
        catList.push(820457);
      if(isFlexibleActif)
        catList.push(186239);
      const filteredData = posts.filter(post => {
        const { title, excerpt, categories } = post.node;
        return (
          
          (title.toLowerCase().includes(query.toLowerCase()) 
          || excerpt.toLowerCase().includes(query.toLowerCase()))
          && catList.every( cat => categories.includes(cat))
          // ||
          // (tags &&
          //   tags
          //     .join("")
          //     .toLowerCase()
          //     .includes(query.toLowerCase()))
        )
      })
      if((filteredData && query !== emptyQuery) || (filteredData && catList.length > 1))
      {
        onHide();
      }
      else{
        onShow();
          
      }
      setState({
        query,
        filteredData,
        isGlutenFreeActif,
        isLactoseFreeActif,
        isFlexibleActif
      })
      
  }

  function handleClickGluten(){
    const {query , isGlutenFreeActif, isLactoseFreeActif, isFlexibleActif} = state;
    if(isGlutenFreeActif){
        search(query, false , isLactoseFreeActif, isFlexibleActif);
    }else{
      search(query, true , isLactoseFreeActif, isFlexibleActif);
    };
      
  }

  function handleClickLactose() {
    const {query , isGlutenFreeActif, isLactoseFreeActif, isFlexibleActif} = state;
    if(isLactoseFreeActif){
      search(query, isGlutenFreeActif , false, isFlexibleActif);
    }else{
      search(query, isGlutenFreeActif , true, isFlexibleActif);
    }
  }

  function handleClickFlexible() {
    const {query , isGlutenFreeActif, isLactoseFreeActif, isFlexibleActif} = state;
    if(isFlexibleActif){
      search(query, isGlutenFreeActif , isLactoseFreeActif, false);
    }else{
      search(query, isGlutenFreeActif , isLactoseFreeActif, true);
    }
  }

  const handleInputFocus = event => {
    const { isGlutenFreeActif, isLactoseFreeActif, isFlexibleActif} = state;
    const queryevent = event.target.value
    if(queryevent !== ""){
      search(event.target.value, isGlutenFreeActif, isLactoseFreeActif, isFlexibleActif);
    }
  }

  const { filteredData, query , isGlutenFreeActif, isLactoseFreeActif, isFlexibleActif} = state;
  const hasSearchResults = (filteredData && query !== emptyQuery) || (filteredData && (isGlutenFreeActif || isLactoseFreeActif || isFlexibleActif));
  const posts = hasSearchResults ? filteredData : [];
  var index = 0;
  
  return (
    <>
      <div className="wrap">
        <div className="categories">
          <button className={"category__item " + (isGlutenFreeActif ? "cat_active" : "")} onClick={handleClickGluten}>Gluten Free</button>
          <button className={"category__item " + (isLactoseFreeActif ? "cat_active" : "")} onClick={handleClickLactose}>Lactose Free</button>
          <button className={"category__item " + (isFlexibleActif ? "cat_active" : "")} onClick={handleClickFlexible}>Flexible (Be Free)</button>
        </div>
        <div className="search">
            <input type="text" className="searchTerm" placeholder="Je veux la meilleure recette de...." onChange={handleInputChange} onFocus={handleInputFocus} />
            <button type="submit" className="searchButton">
              <i className="fa fa-search"></i>
            </button>
            <div className="postCount">{filteredData.length !== 0 ? filteredData.length : (filteredData.length === 0 && query ? "0" : allPosts.length)}</div>
        </div>
      </div>
      
      <div>
        {posts.map(({ node }) => {
          return (
            <div key={index} className={"blog-card " + (index++ % 2 === 0 ? 'alt' : '')} data-aos="fade">
            <div className="meta">
              <Link to={node.slug}>
                <object className="photo" data={node.jetpack_featured_media_url} type="image/jpg">
                </object>
              </Link>
            </div>
            <div className="description">
            <Link to={"../../recettes/" + node.slug}>
              <h1 dangerouslySetInnerHTML={{ __html: node.title }}></h1>
            </Link>
              
              <div className="cat">
              {node.categories.length > 1 && node.categories.map(( cat ) => (
                <span key={cat} >
                  {cat === 820457 ? " Lactose Free " : ""}
                  {cat === 28249 ? " Gluten Free " : ""}
                  {cat === 186239 ? " Flexible " : ""}
                </span>
              ))}
              </div> 
              <div>
                {Parser(node.excerpt, {
                      replace: function(domNode) {
                        if (domNode.name === 'a') {
                          return (
                            <span >
                              ...
                              <br></br>
                              <span className="read-more">
                                <Link to={"../../recettes/" + node.slug}><b>Lire la suite...</b> </Link>
                              </span>
                            </span>)
                        }
                      }})}
              </div>
              <div className="date">{node.date}</div>
            </div>
            
          </div>
          )
        })}
      </div>
    </>
  )
}
export default SearchBlog
